import React, { Component } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { Button, Container, Icon, Image, Menu, Sidebar as SemanticSidebar, Header } from 'semantic-ui-react'
import Sidebar from '../components/Sidebar'
import { InlineMenu } from '../components/Nav'
import routes from '../routes'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { logoutUser } from '../scenes/Login/actions'
import ProtectedRoute from '../components/ProtectedRoute'
// import Login from './scenes/Auth/Login'

import squareImage from '../assets/images/avatar/small/elliot.jpg';
import iramaIconLogo from '../assets/images/logo/logo-main.png';

import ModalRoot from '../components/Modal';
import UserDropdown from '../scenes/User/components/UserDropdown';

class MainLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false
    }

    this.toggleVisibility = this.toggleVisibility.bind(this)
  }

  toggleVisibility = () => this.setState({ visible: !this.state.visible })

  render() {
    const { visible } = this.state

    return (
      <Container fluid id="wrapper" >
      <Container fluid className="top-navigation">
        <Menu borderless fixed="top" className="top-nav">
          <Menu.Item>
            <Button as={Icon} size='large' className="sidebar-toggler" icon='bars' active={visible} onClick={this.toggleVisibility} />
          </Menu.Item>
          <Menu.Item header className="logo">
            <Header as="h1">Seroffice</Header>
          </Menu.Item>
          <InlineMenu />
          <Menu.Menu position='right'>
            <UserDropdown as={Menu.Item}/>
            <Menu.Item icon="setting" as={Link} to="/configuracion" />
            <Menu.Item icon="sign out" onClick={this.props.logoutUser} />
          </Menu.Menu>
        </Menu>
      </Container>
      <SemanticSidebar.Pushable >
        <Sidebar visible={visible} animation="push" vertical>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.sidebar}
              />
            ))}
          </Switch>
        </Sidebar>
        <SemanticSidebar.Pusher>
          <Container fluid className="main">
            <Switch>
              {routes.map((route, index) => (
                <ProtectedRoute
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.main}
                />
              ))}
            </Switch>
          </Container>
        </SemanticSidebar.Pusher>
        <ModalRoot />
      </SemanticSidebar.Pushable>
      </Container>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.authentication.user && state.authentication.user.user || {}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // loadUser: (_id) => dispatch(loadUser(_id)),
    logoutUser: () => dispatch(logoutUser())
  }
}

export default
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
  )(MainLayout))
